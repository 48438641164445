<template>
  <wizzard-container :infos="infos">
    <help-center-link
      topic="create-location"
      link-text="Kako uneti lokaciju?"
      mode="info"
    />
    <div class="title">{{$t('Form Location Title')}}</div>
    <p class="caption">{{$t('Form Location Subtitle')}}</p>

    <v-select
      dense
      outlined
      v-model="form.country_id"
      :items="countryItems"
      item-text="name"
      item-value="id"
      :label="$t('Form Location Country')"
      :error-messages="firstError('form.country_id')"
      persistent-hint
      @change="countryChanged"
    ></v-select>

    <v-row>
      <v-col v-if="showLocationsForm">
        <!-- <gmap-autocomplete
          ref="autocomplete"
          :select-first-on-enter="true"
          @place_changed="setPlace('autoComplete', $event)"
          class="w-50"
        ></gmap-autocomplete> -->
        <vuetify-google-autocomplete
          outlined
          dense
          :error-messages="autocompleteErrors"
          id="map"
          :country="countryCode"
          types='geocode'
          ref="autocomplete"
          :placeholder="$t('Form Location Autocomplete Placeholder')"
          v-on:placechanged="getAddressData"
          :hint="$t('Form Location Autocomplete Hint')"
          persistent-hint
        >
        <!-- https://developers.google.com/places/supported_types -->
          <!-- :enable-geolocation="true" -->
        </vuetify-google-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex">
        <v-text-field
          class="mr-5"
          v-model="form.floor"
          outlined
          :label="$t('Form Location Floor')"
          :placeholder="$t('Form Location Floor')"
          :hint="$t('Form Location Floor Hint')"
          persistent-hint
          dense
        />
        <v-text-field
          v-model="form.suite_number"
          outlined
          :label="$t('Form Location SuiteNumber')"
          :placeholder="$t('Form Location SuiteNumber Placeholder')"
          :hint="$t('Form Location SuiteNumber Hint')"
          persistent-hint
          dense
        />
      </v-col>
    </v-row>

    <div v-if="form.latitude && form.longitude" class="mt-5">
      <GmapMap
        ref="mapRef"
        :center="{ lat:form.latitude, lng:form.longitude }"
        :zoom="16"
        style="width: 100%; height: 250px"
        @dragend="dragend"
      >
      </GmapMap>
      <p class="caption">{{$t('Form Location drag and drop pin to exact location')}}</p>
    </div>

    <form-action-buttons
      @submit="submit"
      @prev="$emit('prev')"
      :status="ad.status" />
  </wizzard-container>
</template>
<style lang="scss">
.vue-map:after {
  display: block;
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    background: url( '~@/assets/icons/map_pin_home2.svg' );
    pointer-events: none;
    width: 40px;
    height: 54px;
    margin: -54px 0 0 -20px;
    background-size: 40px 54px;
}
</style>
<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import axios from 'axios';
import { gmapApi } from 'vue2-google-maps';
import FormWizardMixin from '@/lib/FormWizardMixin';
import FormValidationMixin from '@/lib/FormValidationMixin';
import HelpCenterLink from '@/components/HelpCenterLink.vue';
import WizzardContainer from './WizzardContainer.vue';

export default {
  mixins: [validationMixin, FormValidationMixin, FormWizardMixin],
  components: {
    WizzardContainer,
    HelpCenterLink,
  },
  validations: {
    form: {
      street: {
        required,
        txtMinLen: minLength(2),
      },
      country_id: {
        required,
      },
      // street_number: {
      //   required,
      // },
    },
  },
  data() {
    return {
      form: {
        id: undefined,
        citySuggestion: '',
        country_id: undefined,
        latitude: undefined,
        longitude: undefined,
        floor: undefined,
        street: undefined,
        street_number: undefined,
        suite_number: undefined,
        city_id: undefined,
        suite: '',
      },
      countryOptions: [],
      showLocationsForm: false,
      countryCode: 'rs',
    };
  },
  computed: {
    infos() {
      const infos = [
        {
          icon: 'green-energy',
          title: 'Form Location InfoTitle',
          description: 'Form Location InfoDescription',
        },
      ];
      if (this.$i18n.locale === 'sr') {
        infos.push(
          {
            title: 'Form Location Tutorial Title',
            description: '',
            youtube: 'https://www.youtube.com/embed/83jGIBBLCFk',
          },
        );
      }
      return infos;
    },
    countryItems() {
      return this.countryOptions.map((country) => (
        {
          name: country.name[this.$i18n.locale],
          id: country.id,
          disabled: false,
          divider: false,
          // header: false,
        }
      ));
    },
    ...mapGetters(['loading']),
    google: gmapApi,
    autocompleteErrors() {
      // https://github.com/vuelidate/vuelidate/issues/22
      if (this.$v.form.street.$error) {
        return [this.$t('Form Location Street Error')];
      }
      // if (this.$v.form.street_number.$error) {
      //   // return !this.$v.street_number.required;
      //   return ['Morate uneti Broj ulice. Primer "Makedonska 34"'];
      // }
      return [];
    },
  },
  // watch: {
  //   ad() {
  //     this.setFormData();
  //   },
  // },
  mounted() {
    this.getCountries();
  },
  methods: {
    countryChanged(id) {
      this.countryCode = this.getCountryCode(id);
      this.resetFormData();
    },
    getCountryCode(id) {
      if (this.countryOptions.length) {
        const country = this.countryOptions.find((c) => c.id === id);
        return country.code;
      }
      return 'rs';
    },
    resetFormData() {
      this.form.floor = undefined;
      this.form.street = undefined;
      this.form.street_number = undefined;
      this.form.suite_number = undefined;
      this.form.latitude = undefined;
      this.form.longitude = undefined;
      this.$v.$reset();

      if (this.$refs.autocomplete) {
        this.$refs.autocomplete.update();
      }
    },
    getCountries() {
      this.$store
        .dispatch('getCountries')
        .then((countries) => {
          this.countryOptions = countries;
          this.setFormData();
        });
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.scrollToFirstError();
        return;
      }
      this.$store.dispatch('loading', true);
      axios
        .post('panel/ads/validation/location', {
          ...this.form,
        })
        .then(() => {
          this.$store.dispatch('message', this.$t('AdForm Next Step Message'));
          this.$store.dispatch('loading', false);
          this.$emit('success');
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
          this.scrollToFirstError();
        });
    },
    getAddressData(addressData) { // , placeResultData, id
      // console.log('addressData');
      // console.log(addressData);
      this.form.citySuggestion = addressData.locality;
      this.form.latitude = addressData.latitude;
      this.form.longitude = addressData.longitude;
      this.form.street = addressData.name; // .route je za ulicu
      this.form.street_number = addressData.street_number
        ? addressData.street_number
        : undefined;
      // if (this.$v.form.street_number) { // ToDo delete this if street_number is not required
      //   this.$v.form.street_number.$touch();
      // }
    },
    dragend() {
      this.$refs.mapRef.$mapPromise.then((map) => {
        this.form.latitude = map.center.lat();
        this.form.longitude = map.center.lng();
      });
    },
    setFormData() {
      if (this.ad && Object.keys(this.ad).length !== 0) {
        this.form.id = this.ad.id;
        this.form.country_id = this.ad.country_id;
        this.form.city_id = this.ad.city_id;
        this.form.floor = this.ad.floor;
        this.form.street = this.ad.street;
        this.form.street_number = this.ad.street_number;
        this.form.suite_number = this.ad.suite_number;
        this.form.latitude = Number(this.ad.latitude);
        this.form.longitude = Number(this.ad.longitude);
      }

      this.countryCode = this.getCountryCode(this.form.country_id);

      this.showLocationsForm = true;

      this.$nextTick(() => {
        if (this.$refs.autocomplete) {
          this.$refs.autocomplete.update(
            !this.form.street_number
              ? this.form.street
              : `${this.form.street} ${this.form.street_number}`,
          );
        }
      });
    },
  },
};
</script>
