<template>
  <wizzard-container :infos="infos">
      <help-center-link
        topic="create-basic-information"
        link-text="Kako uneti osnovne informacije?"
        mode="info"
      />
      <div class="title">{{$t('Form Info Name Title')}}</div>
      <p class="caption">{{$t('Form Info Name Subtitle')}}</p>
      <v-text-field
          v-model="form.name"
          :error-messages="firstError('form.name')"
          class="mt-2"
          outlined
          counter="25"
          :label="$t('Form Info Name')"
          :placeholder="$t('Form Info Name')"
          dense
      />

      <div class="title">{{$t('Form Info Description Title')}}</div>
      <p class="caption">{{$t('Form Info Description Subtitle')}}</p>
      <v-textarea
        v-model="form.description[$i18n.locale]"
        :error-messages="firstError(`form.description.${$i18n.locale}`)"
        class="mt-2"
        outlined
        counter="2000"
        :label="$t('Form Info Description')"
        :placeholder="$t('Form Info Description')"
        dense
      >
        <template slot="prepend">
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :color="showTranslatable ? 'primary' : 'grey'"
              dark
              v-bind="attrs"
              v-on="on"
              @click="toggleTranslations">mdi-translate</v-icon>
          </template>
          <span v-if="showTranslatable">
            {{$t('Hide other locales')}}
          </span>
          <span v-else>
            {{$t('Show other locales')}}
          </span>
        </v-tooltip>
        </template>
      </v-textarea>

      <div v-if="showTranslatable">
        <v-textarea
          v-for="locale in otherLocales"
          :key="locale"
          v-model="form.description[locale]"
          :error-messages="firstError(`form.description.${locale}`)"
          class="mt-2"
          outlined
          counter="2000"
          :label="`${$t('Form Info Description')} ${locale}`"
          :placeholder="`${$t('Form Info Description')} ${locale}`"
          dense
        />
      </div>
      <form-action-buttons
        @submit="submit"
        :status="ad.status"
        hide-back-button/>
  </wizzard-container>
</template>
<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, requiredIf, minLength } from 'vuelidate/lib/validators';
import axios from 'axios';
import FormWizardMixin from '@/lib/FormWizardMixin';
import FormValidationMixin from '@/lib/FormValidationMixin';
import HelpCenterLink from '@/components/HelpCenterLink.vue';
import WizzardContainer from './WizzardContainer.vue';

export default {
  mixins: [validationMixin, FormValidationMixin, FormWizardMixin],
  components: {
    WizzardContainer,
    HelpCenterLink,
  },
  validations: {
    form: {
      name: {
        required,
        txtMinLen: minLength(2),
      },
      description: {
        sr: {
          required: requiredIf(function () {
            return this.$i18n.locale === 'sr';
          }),
          txtMinLen: minLength(2),
        },
        en: {
          required: requiredIf(function () {
            return this.$i18n.locale === 'en';
          }),
          txtMinLen: minLength(2),
        },
      },
    },
  },
  data() {
    return {
      form: {
        id: null,
        name: undefined,
        description: {
          en: undefined,
          sr: undefined,
        },

        category_id: undefined,
      },
      infos: [
        {
          icon: 'green-energy',
          title: 'Form Info InfoTitle',
          description: 'Form Info InfoDescription',
        },
      ],
      showTranslatable: false,
    };
  },
  computed: {
    ...mapGetters(['loading']),
    otherLocales() {
      return this.$i18n.availableLocales.filter((locale) => locale !== this.$i18n.locale);
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.scrollToFirstError();
        return;
      }
      this.$store.dispatch('loading', true);
      axios
        .post('panel/ads/validation/info', {
          ...this.form,
        })
        .then(({ data }) => {
          this.$store.dispatch('clearErrors');
          this.$store.dispatch('message', this.$t('AdForm Next Step Message'));
          this.$store.dispatch('loading', false);

          if (!this.form.id) {
            this.$router.push({ name: 'AdForm', params: { id: data.id.toString(), categoryConst: this.category.const } });
          } else {
            this.$emit('success');
          }
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
          this.scrollToFirstError();
        });
    },
    toggleTranslations() {
      this.showTranslatable = !this.showTranslatable;
    },
  },
  mounted() {
    if (this.ad && Object.keys(this.ad).length !== 0) {
      this.form.id = this.ad.id;
      this.form.name = this.ad.name;
      this.form.description = this.ad.description;
      this.form.category_id = this.ad.category_id;
    } else {
      this.form.category_id = this.category.id;
    }
  },
};
</script>
