<template>
  <wizzard-container :infos="infos">
    <help-center-link
      topic="create-reservations"
      link-text="Kako podesiti rezervacije?"
      mode="info"
    />
    <div class="title">{{$t('Form Reservation Title')}}</div>

    <v-radio-group v-model="form.instant">
      <template v-slot:label>
        <div>{{$t('Form Reservation Instant Title')}}?</div>
      </template>
      <v-radio :value="1">
        <template v-slot:label>
          <div>
            <strong>Instant</strong>
            <br/>
            <small>
              {{$t('Form Reservation Instant Description')}}
            </small>
          </div>
        </template>
      </v-radio>
      <v-radio :value="0">
        <template v-slot:label>
          <div>
            <strong>{{$t('Form Reservation Upit')}}</strong>
            <br/>
            <small>{{$t('Form Reservation Upit Description')}}</small>
          </div>
        </template>
      </v-radio>
    </v-radio-group>

    <v-row>
      <v-col>
        <v-text-field
            v-model="form.min_stay"
            :error-messages="firstError('form.min_stay')"
            type="number"
            class="mt-2"
            outlined
            :suffix="$t('nights')"
            :label="$t('Form Reservation MinStay')"
            :placeholder="$t('Form Reservation MinStay')"
            :hint="`${$t('Form Reservation MinStay Hint Start')}
             ${form.min_stay} ${$t('Form Reservation MinStay Hint End')}`"
            persistent-hint
            min="1"
            dense
        />
      </v-col>
    </v-row>

    <div class="title mt-3">{{ $t('Form Reservation MinStay Weekly Title') }}</div>
    <v-row>
      <v-col
        v-for="day in ['monday','tuesday','wednesday','thursday','friday','saturday','sunday']"
        :key="day"
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="form[`min_stay_${day}`]"
          :error-messages="firstError(`form.min_stay_${day}`)"
          clearable
          type="number"
          class="mt-2"
          :label="$t(`Day Name Short.${day[0].toUpperCase()+day.slice(1)}`)"
          :suffix="$t('nights')"
          outlined
          min="1"
          max="90"
          dense
        />
      </v-col>
    </v-row>

    <!-- <div class="title">Dolazak i Odlazak</div> -->

    <div class="title mt-3">{{$t('Form Reservation CheckIn Title')}}</div>
      <v-row>
        <v-col cols="12" sm="6">
          <v-select
            v-model="form.checkin_from"
            :error-messages="firstError('form.checkin_from')"
            :items="checkinOptions"
            item-text="text"
            item-value="value"
            :label="$t('Form Reservation CheckInFrom')"
            dense
            outlined
            @change="form.checkin_to = null"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6">
          <v-select
            v-show="form.checkin_from !== 'flex'"
            v-model="form.checkin_to"
            :error-messages="firstError('form.checkin_to')"
            :items="checkinToOptions"
            :disabled="form.checkin_from === 'flex'"
            item-text="text"
            item-value="value"
            :label="$t('Form Reservation CheckInTo')"
            dense
            outlined
          ></v-select>
      </v-col>
    </v-row>

    <div class="title mt-3">{{$t('Form Reservation CheckOut Title')}}</div>
    <v-row>
      <v-col cols="12" md="6">
        <v-select
          v-model="form.checkout"
          :error-messages="firstError('form.checkout')"
          class=""
          :items="checkoutOptions"
          item-text="text"
          item-value="value"
          :label="$t('Form Reservation CheckOut')"
          dense
          outlined
        ></v-select>
      </v-col>
    </v-row>

    <div class="title mt-3" >{{$t('Form Reservation Icals Title')}}</div>

      <div v-if="ad.detail.icals">
        <div

          v-for="calendar in ad.detail.icals"
          :key="calendar.id"
        >
          <div class="d-flex justify-space-between my-1">
            <template>
              <div class="d-flex align-center">
                <span>{{ calendar.name }}</span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click.stop="copyImportIcalUrl(calendar.url)"
                      class="cursor-pointer ml-2"
                      dense
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-content-copy
                    </v-icon>
                  </template>
                  <span>{{$t('Form Reservation Icals Copy')}}</span>
                </v-tooltip>
              </div>
            </template>

            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click.stop="refreshCalendar"
                    class="cursor-pointer mr-2"
                    dense
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>{{$t('Form Reservation Icals Refresh')}}</span>
              </v-tooltip>
              <confirm @save="deleteIcal(calendar.id)"/>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-center mt-3">
        <v-icon color="black darken-2" dense>mdi-calendar-import</v-icon>
        <span
          @click="showImportForm = true"
          class="ml-2 cursor-pointer text-decoration-underline">{{$t('Import Calendar')}}</span>
      </div>
      <import-calendar v-if="showImportForm" @close="showImportForm = false; showExportForm = false" :ad="ad"/>
      <div class="d-flex align-center mt-3">
        <v-icon color="black darken-2" dense>mdi-calendar-export</v-icon>
        <span
          @click="showExportForm = true; showImportForm = false;"
          class="ml-2 cursor-pointer text-decoration-underline">{{$t('Export Calendar')}}</span>
      </div>
      <v-card v-if="showExportForm" outlined class="my-3">
        <v-card-title dense>{{$t('Export Calendar')}}</v-card-title>
        <v-card-text class="pb-0">
          <p>{{$t('Export Calendar Description')}}</p>
          <v-text-field
              @click.stop.prevent="copyExportIcalUrl"
              class="mt-2 cursor-pointer-on-input"
              outlined
              readonly
              :value="exportIcalUlr"
              dense
          />
        </v-card-text>
        <v-card-actions class="pl-4 pb-4 pt-0">
          <v-btn
            :loading="loading"
            color="primary"
            outlined
            @click.prevent="copyExportIcalUrl"
          >
            {{$t('Copy URL')}}
          </v-btn>
          <v-btn
            text
            @click="showExportForm = false"
          >
            {{$t('Cancel')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    <form-action-buttons
      @submit="submit"
      @prev="$emit('prev')"
      :status="ad.status"/>

  </wizzard-container>
</template>
<style>
.cursor-pointer-on-input input{
  cursor: pointer !important;
}
.cursor-pointer-on-input .v-input__slot:hover {
  background: #f2f2f2;;
}
</style>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required, requiredIf, minValue, maxValue,
} from 'vuelidate/lib/validators';
import axios from 'axios';
import Confirm from '@/components/Confirm.vue';
import FormWizardMixin from '@/lib/FormWizardMixin';
import FormValidationMixin from '@/lib/FormValidationMixin';
import HelpCenterLink from '@/components/HelpCenterLink.vue';
import WizzardContainer from '../WizzardContainer.vue';
import ImportCalendar from './ImportCalendar.vue';

export default {
  mixins: [validationMixin, FormValidationMixin, FormWizardMixin],
  components: {
    WizzardContainer,
    ImportCalendar,
    Confirm,
    HelpCenterLink,
  },
  validations: {
    form: {
      instant: {
        required,
      },
      min_stay: {
        required,
      },
      min_stay_monday: {
        minValue: minValue(1),
        maxValue: maxValue(90),
      },
      min_stay_tuesday: {
        minValue: minValue(1),
        maxValue: maxValue(90),
      },
      min_stay_wednesday: {
        minValue: minValue(1),
        maxValue: maxValue(90),
      },
      min_stay_thursday: {
        minValue: minValue(1),
        maxValue: maxValue(90),
      },
      min_stay_friday: {
        minValue: minValue(1),
        maxValue: maxValue(90),
      },
      min_stay_saturday: {
        minValue: minValue(1),
        maxValue: maxValue(90),
      },
      min_stay_sunday: {
        minValue: minValue(1),
        maxValue: maxValue(90),
      },
      checkin_from: {
        required,
      },
      checkin_to: {
        // eslint-disable-next-line
        required: requiredIf(function () {
          return this.form.checkin_from !== 'flex';
        }),
      },
      checkout: {
        required,
      },
    },
  },
  data() {
    return {
      form: {
        id: null,
        instant: false,
        min_stay: 1,
        min_stay_weekend: 1,
        checkin_to: 1,
        checkin_from: 1,
        checkout: 1,
      },
      showImportForm: false,
      showExportForm: false,
      // infos: [
      //   {
      //     icon: 'green-energy',
      //     title: 'Form Reservation InfoTitle',
      //     description: 'Form Reservation InfoDescription',
      //   },
      //   {
      //     title: 'Form Reservation MinStay Tutorial Title',
      //     description: '',
      //     youtube: 'https://www.youtube.com/embed/YdRKJf52cqM',
      //   },
      //   {
      //     title: 'Form Reservation Sync Tutorial Title',
      //     description: '',
      //     youtube: 'https://www.youtube.com/embed/nv35xXuHKQg',
      //   },
      // ],
      checkinOptions: [
        {
          value: 'flex',
          text: this.$t('Flexible'),
        },
        {
          value: '08:00',
          text: '08:00',
        },
        {
          value: '09:00',
          text: '09:00',
        },
        {
          value: '10:00',
          text: '10:00',
        },
        {
          value: '11:00',
          text: '11:00',
        },
        {
          value: '12:00',
          text: '12:00',
        },
        {
          value: '13:00',
          text: '13:00',
        },
        {
          value: '14:00',
          text: '14:00',
        },
        {
          value: '15:00',
          text: '15:00',
        },
        {
          value: '16:00',
          text: '16:00',
        },
        {
          value: '17:00',
          text: '17:00',
        },
        {
          value: '18:00',
          text: '18:00',
        },
        {
          value: '19:00',
          text: '19:00',
        },
        {
          value: '20:00',
          text: '20:00',
        },
        {
          value: '21:00',
          text: '21:00',
        },
        {
          value: '22:00',
          text: '22:00',
        },
        {
          value: '23:00',
          text: '23:00',
        },
        {
          value: '00:00',
          text: '00:00',
        },
        {
          value: '01:00',
          text: `01:00 (${this.$t('next day')})`,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['loading']),
    checkinToOptions() {
      const index = this.checkinOptions.findIndex((item) => item.value === this.form.checkin_from);
      const options = this.checkinOptions.slice(index + 2);
      options.unshift({
        value: 'flex',
        text: this.$t('Flexible'),
      });
      options.push({
        value: '02:00',
        text: `02:00 (${this.$t('next day')})`,
      });
      return options;
    },
    checkoutOptions() {
      const options = [];
      for (let i = 0; i < 24; i += 1) {
        options.push({
          value: i > 9
            ? `${i}:00`
            : `0${i}:00`,
          text: i > 9
            ? `${i}:00`
            : `0${i}:00`,
        });
      }
      return options;
    },
    exportIcalUlr() {
      return `${process.env.VUE_APP_APP_URL}/ad/${this.ad.id}/ical`;
    },
    infos() {
      const infos = [
        {
          icon: 'green-energy',
          title: 'Form Reservation InfoTitle',
          description: 'Form Reservation InfoDescription',
        },
      ];
      if (this.$i18n.locale === 'sr') {
        infos.push(
          {
            title: 'Form Reservation MinStay Tutorial Title',
            description: '',
            youtube: 'https://www.youtube.com/embed/YdRKJf52cqM',
          },
          {
            title: 'Form Reservation Sync Tutorial Title',
            description: '',
            youtube: 'https://www.youtube.com/embed/nv35xXuHKQg',
          },
        );
      }
      return infos;
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.scrollToFirstError();
        return;
      }
      this.$store.dispatch('loading', true);
      axios
        .post('panel/ads/validation/reservations', {
          ...this.form,
        })
        .then(() => {
          this.$store.dispatch('message', this.$t('AdForm Next Step Message'));
          this.$store.dispatch('loading', false);
          this.$emit('success');
          // console.log(data);
          // this.$emit('setApartment', data);
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
          this.scrollToFirstError();
        });
    },
    deleteIcal(id) {
      this.$store.dispatch('loading', true);
      axios
        .post('panel/ads/validation/remove-calendar', {
          id: this.ad.id,
          ical_id: id,
        })
        .then(() => {
          this.$store.dispatch('message', this.$t('Calendar deleted'));
          this.$store.dispatch('loading', false);
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
    },
    refreshCalendar() {
      this.$store.dispatch('loading', true);
      this.$store
        .dispatch('refreshIcal', this.ad.id)
        .then(() => {
          this.$store.dispatch('message', this.$t('Calendar synchronized'));
          this.$store.dispatch('loading', false);
        })
        .catch((error) => {
          console.warn('ERROR');
          console.warn(error);
          this.$store.dispatch('loading', false);
        });
    },
    copyExportIcalUrl() {
      navigator.clipboard.writeText(this.exportIcalUlr);
      this.$store.dispatch('message', this.$t('Link copied to clipboard'));
    },
    copyImportIcalUrl(url) {
      navigator.clipboard.writeText(url);
      this.$store.dispatch('message', this.$t('Link copied to clipboard'));
    },
  },
  mounted() {
    if (this.ad && this.ad.id) {
      this.form.id = this.ad.id;
    }
    if (this.ad.detail) {
      this.form.instant = this.ad.detail.instant;
      this.form.checkin_to = this.ad.detail.checkin_to;
      this.form.checkin_from = this.ad.detail.checkin_from;
      this.form.checkout = this.ad.detail.checkout;
      this.form.min_stay = this.ad.detail.min_stay;
      this.form.min_stay_monday = this.ad.detail.min_stay_monday;
      this.form.min_stay_tuesday = this.ad.detail.min_stay_tuesday;
      this.form.min_stay_wednesday = this.ad.detail.min_stay_wednesday;
      this.form.min_stay_thursday = this.ad.detail.min_stay_thursday;
      this.form.min_stay_friday = this.ad.detail.min_stay_friday;
      this.form.min_stay_saturday = this.ad.detail.min_stay_saturday;
      this.form.min_stay_sunday = this.ad.detail.min_stay_sunday;
    }
  },
};
</script>
