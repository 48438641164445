<template>
  <wizzard-container full-width>
    <div class="title">{{$t('Activity Log')}}</div>
    <p class="caption">{{$t('Form Terms Subtitle')}}</p>

    <activity-log :ads="[ad.id]" hide-ads-filter/>

  </wizzard-container>
</template>
<script>
import { mapGetters } from 'vuex';
import FormWizardMixin from '@/lib/FormWizardMixin';
import ActivityLog from '@/components/activity-log/ActivityLog.vue';
import WizzardContainer from './WizzardContainer.vue';

export default {
  mixins: [FormWizardMixin],
  components: {
    WizzardContainer,
    ActivityLog,
  },
  data() {
    return {
      info: {
        icon: 'green-energy',
        title: 'Form Terms InfoTitle',
        description: 'Form Terms InfoDescription',
      },
    };
  },
  computed: {
    ...mapGetters(['loading']),
  },
  methods: {
  },
  mounted() {
  },
};
</script>
